const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge({}, config, {
  /**
   * common configuration here
   */
  siteId: '5e97016e161766ccc7a56e74',
  disabledComponents: ['wButtonLike'],
  showCardDescription: true,
  enableExperimentalBlocks: true,
  authMode: [['local', { mode: 'otp' }]],
  metadatas: {
    name: 'BK LES PODCASTS',
    colorPrimary: '#e30613',
    supportEmailAddress: 'support@bklespodcasts.fr'
  },
  analytics: {
    mixpanel: 'ce3ad86011c347cb4a9ccd5168269e89',
    ga: 'UA-111736601-36'
  },
  useShell: [
    'bb-default',
    {
      mode: 'list',
    },
  ],
  theme: {
    additionalStyles: ['~/excentrics/assets/style/blockbe-font.styl'],
    components: {
      'toolbar-title': {
        style: {
          fontFamily: 'BlockBE',
        },
      },
    },
  },
},
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)

